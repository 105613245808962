@media screen and (min-width: 500px) {
  .footer-container {
    background: url("./../../../../../public/contents/images/footer-background.png") no-repeat;
    padding-top: 96px;
    padding-bottom: 8px;
    align-content: center;
    .footer-item-logo {
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 8px 0;
    }
    .footer-item-social-link {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      margin: 8px 0;
    }
    .footer-item-copy-right {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
      .copy-right {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #1B4860;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-container {
    background: url("./../../../../../public/contents/images/footer-background.png") no-repeat;
    padding-top: 64px;
    padding-bottom: 8px;
    align-content: center;
    height: 200px;
    .footer-item-logo {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px;
    }
    .footer-item-social-link {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      padding: 4px;
    }
    .footer-item-copy-right {
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 4px;
      .copy-right {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: #1B4860;
      }
    }
  }
}
