.footer {
  padding: 0;

  .MuiPaper-root {
    background-color: #eee;
    line-height: 48px;
    align-items: center;

    .icon-cart {
      padding-left: 8px;
    }

    .view-cart {
      width: calc(100% - 74px);
      padding-right: 8px;
      border: none;
      box-shadow: none;
      .card-content {
        height: 48px;
      }
      .total-amount {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .quantity-total {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      color: #fff;
      padding: 0px;
      line-height: 1.5;
      font-size: 0.875rem;
      background-color: crimson;
      &.MuiTypography-root {
        margin-top: -5px;
      }
    }

    .checkout-button {
      width: 126px;
      border-radius: 0;
      button {
        color: #fff;
        text-transform: none;
        font-size: 14px;
        height: 48px;
        background-color: #c69a4b;
        border-radius: 0;
      }
    }
  }
}
