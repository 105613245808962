.header {
  background-color: #FAC8C9 !important;
  align-items: center;
  border-bottom: 2px solid #292526;
  position: fixed;
  .nav-bar-menu {
    .MuiButtonBase-root {
      .menu-item {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        text-decoration: none;
        text-transform: none;
        color: #292526;
        margin-right: 40px;
        display: flex;
        justify-self: center;
      }
    }
  }

  .icon-button {
    border-radius: 24;
    border: 1.5px solid #292526;
    padding: 8px;
    svg {
      width: 24px;
      height: 24px;
      color: black;
    }
  }

  .menu-item-icon {
    margin-left: -36px;
  }

  .menu-list {
    .MuiPaper-root.MuiPaper-elevation {
      background-color: #def9ff !important;
    }
  }
}

.menu-item {
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    text-transform: none;
    color: #292526;
  }
  .our-menu-link {
    display: flex;
    justify-content: space-between;
  }
}
