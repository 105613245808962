html,
body {
  min-height: 100%;
  font-size: 14px;
  color: #411616;
  font-family: Nunito, sans-serif;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.container {
  position: relative;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-content {
  padding-bottom: 48px;
  overflow-x: hidden;
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
