.store-information-container {
  @media screen and (min-width: 500px) {
    & {
      padding-top: 16px;
      padding-bottom: 46px;
    }
    .store-information-title {
      font-family: Poppins;
      font-size: 48px;
      font-weight: 700;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: left;
    }

    .store-info-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 2px solid #292526;
      padding: 32px;
      border-radius: 24px;
      background-color: #96D5DD;

      .store-name {
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #292526;
      }
      .store-info-card-item-container {
        .store-info-card-item-title {
          font-family: Inter;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .working-time-item-container {
        display: flex;
        gap: 16px;
        align-content: center;
        img {
          margin-top: 12px;
        }

        .working-time-item-content {
          display: flex;
          gap: 16px;
          .working-time-item-left {
            border-right: 1px solid #292526;
            padding-right: 16px;
          }
          .working-time-value {
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }

    .get-direction-link {
      padding-top: 12px;
      padding-left: 16px;
      padding-bottom: 12px;
      padding-right: 16px;
      border: 2px solid #292526;
      text-decoration: none;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #292526;
      border-radius: 99px;
      background-color: #fff;
    }
  }

  @media screen and (max-width: 500px) {
    & {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .store-information-title {
      font-family: Inter;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }

    .store-info-container {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 2px solid #292526;
      padding: 32px;
      border-radius: 24px;
      background-color: #96D5DD;

      .store-name {
        font-family: Inter;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #292526;
      }
      .store-info-card-item-container {
        .store-info-card-item-title {
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .working-time-item-container {
        display: flex;
        gap: 16px;
        align-content: center;
        img {
          margin-top: 12px;
        }

        .working-time-item-content {
          display: flex;
          gap: 16px;
          .working-time-value {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }

    .get-direction-link {
      padding-top: 12px;
      padding-left: 16px;
      padding-bottom: 12px;
      padding-right: 16px;
      border: 2px solid #292526;
      text-decoration: none;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #292526;
      border-radius: 99px;
      background-color: #fff;
    }
  }
}
