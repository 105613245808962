.cart-item {
  &.css-bhp9pd-MuiPaper-root-MuiCard-root {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }

  .card-header {
    padding: 4px 8px;
    background-color: #eee;

    &.css-46bh2p-MuiCardContent-root:last-child {
      padding-bottom: 0;
    }

    .MuiGrid-root {
      padding-left: 8px;
      padding-right: 8px;
    }

    .MuiTypography-root {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .card-content {
    padding-left: 8px;
    padding-right: 8px;
  }

  .modifier-item {
    height: 32px;
  }
}
