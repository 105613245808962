.order-cart-container {
  .order-cart-detail {
    background-image: url("../../assets/images/back-ground-body.png");
    padding: 8px;
    margin-bottom: 48px;
    @media screen and (max-width: 500px) {
      width: 100%;
      min-width: 370px;
    }
    @media screen and (min-width: 500px) {
      width: 500px;
    }
    .order-cart-header {
      border-bottom: 1px solid #ebcdcb;
    }

    height: 100%;
    overflow: auto;

    .product-item {
      height: auto;
      position: relative;
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: solid 1px #ebcdcb;

      .MuiGrid-root {
        &.MuiGrid-item {
          min-height: fit-content;
        }
      }

      .product-name {
        text-wrap: nowrap !important;
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 0px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: -4px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: 280px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }

      .product-price {
        margin-bottom: 10px;
        color: #9a9a9a;
        font-weight: 500;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        white-space: normal;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 600;
      }

      @media screen and (min-width: 500px) {
        .product-image {
          width: 120px;
          height: 150px;
          object-fit: cover;
        }
      }
      
      @media screen and (max-width: 500px) {
        .product-image {
          width: 90px;
          height: 120px;
          object-fit: cover;
        }
      }

      .product-info {
        &.MuiGrid-item {
          padding-left: 8px;

          .add-to-cart {
            position: absolute;
            bottom: 0;
          }

          .cart-delete-button {
            position: absolute;
            bottom: 0;
            right: 8px;
          }
        }
      }
    }
    .payment-button {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      text-align: center;
      width: 100%;
      .check-out {
        &.MuiButtonBase-root {
          border-radius: 0;
          height: 40px;
          width: 100%;
          background-color: #c69a4b;
          text-transform: none;
          font-size: 14px;
        }
      }
      .place-to-order-button {
        &.MuiButtonBase-root {
          border-radius: 0;
          height: 40px;
          width: 100%;
          background-color: #823d35;
          text-transform: none;
          font-size: 14px;
        }
      }
    }
  }
}
