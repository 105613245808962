.store-info {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 16px;

    .MuiTypography-root {
        color: #333;
        font-weight: 600;
        font-size: 12px;
    }
}