.wrap-content {
  .welcome-container {
    background-color: #FAC8C9;
    .welcome-content {
      height: auto;
      padding-bottom: 32px;
      .left-container {
        @media screen and (min-width: 500px) {
          & {
            padding-top: 82px;
          }
          .title {
            font-family: Poppins;
            font-size: 54px;
            font-weight: 700;
            line-height: 81px;
            letter-spacing: 0em;
            text-align: left;
            text-wrap: wrap;
          }

          .sub-title {
            font-family: Inter;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
          }

          .order-now-button {
            width: 236px;
            height: 84px;
            padding: 32px, 40px, 32px, 40px;
            border-radius: 999px;
            border: 2px solid #292526;
            gap: 10px;
            font-family: Inter;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0em;
            line-height: 34px;
            text-align: left;
            color: #292526;
            text-transform: none;
            background-color: #fff;
            margin-top: 40px;
            &:hover {
              border-radius: 999px;
              box-shadow: -5px 10px #292526;
            }
          }
        }

        @media screen and (max-width: 500px) {
          & {
            padding-top: 8px;
          }
          .title {
            font-family: Poppins;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: left;
            text-wrap: wrap;
          }

          .sub-title {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }

          .order-now-button {
            height: 15px;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 32px;
            padding-right: 32px;
            border-radius: 999px;
            border: 2px solid #292526;
            gap: 10px;
            font-family: Inter;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0em;
            line-height: 34px;
            text-align: left;
            color: #292526;
            text-transform: none;
            background-color: #fff;
            margin-top: 40px;
          }
        }
      }

      .right-container {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        .welcome-logo {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .question-container {
    background: url("./../../../../../public/contents/images/background.png") no-repeat;

    padding-bottom: 32px;

    .question-content {
      border-radius: 40px;
      border: 2px solid #292526;
      overflow: hidden;
      .question-logo-container {
        background: url("../../../../assets/images/question-logo.png") no-repeat
          center/cover;
        min-height: 500px;
      }

      @media screen and (min-width: 500px) {
        .question-content-container {
          background-color: #96D5DD;
          padding-top: 80px;
          padding-bottom: 80px;
          padding-left: 32px;
          padding-right: 32px;
          gap: 64px;

          .question-content-item {
            .question-item-title {
              font-family: Poppins;
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: left;
              color: #292526;
              text-align: center;
            }

            .question-item-content {
              font-family: Inter;
              font-size: 18px;
              font-weight: 500;
              line-height: 27px;
              letter-spacing: 0em;
              text-align: center;
              color: #292526;
            }
          }
        }
      }
      @media screen and (max-width: 500px) {
        .question-content-container {
          background-color: #96D5DD;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;
          gap: 32px;

          .question-content-item {
            .question-item-title {
              font-family: Poppins;
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: left;
              color: #292526;
              text-align: center;
            }

            .question-item-content {
              font-family: Inter;
              font-size: 18px;
              font-weight: 500;
              line-height: 27px;
              letter-spacing: 0em;
              text-align: center;
              color: #292526;
            }
          }
        }
      }
    }

    @media screen and (min-width: 500px) {
      .question-card {
        margin-top: 16px;
        .question-card-container {
          border: 2px solid #292526;
          border-radius: 40px;
          padding: 32px;
          background-color: #96D5DD;
          display: flex;
          flex-direction: column;
          gap: 24px;
          .question-card-title {
            font-family: Poppins;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: left;
            color: #292526;
          }
          .question-card-content {
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            color: #292526;
          }

          .halal {
            margin-left: -24px;
          }
        }
      }

      .click-for-the-menu-button {
        padding: 26px;
        border-radius: 999px;
        border: 2px solid #292526;
        font-family: Inter;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        background-color: #fff;
        color: #292526;
        text-transform: none;
        align-self: center;
        &:hover {
          border-radius: 999;
          box-shadow: -5px 10px #292526;
        }
      }
    }

    @media screen and (max-width: 500px) {
      .question-card {
        margin-top: 16px;
        .question-card-container {
          border: 2px solid #292526;
          border-radius: 40px;
          padding: 32px;
          background-color: #96D5DD;
          display: flex;
          flex-direction: column;
          gap: 24px;
          .question-card-title {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
            color: #292526;
          }
          .question-card-content {
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;
            color: #292526;
          }

          .halal {
            display: flex;
            justify-content: center;
            padding-top: 8px;
          }
        }
      }

      .click-for-the-menu-button {
        padding: 16px;
        border-radius: 999px;
        border: 2px solid #292526;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        background-color: #fff;
        color: #292526;
        text-transform: none;
        align-self: center;
      }
    }
  }
}
