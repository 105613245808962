.product-list {
  .category-group {
    box-shadow: 10px;
    border-radius: 10px;
    padding: 8px;
    background-color: #fff;
    margin-top: 16px;
  }
  .product-item {
    position: relative;
    justify-content: center;

    .product-image-group {
      display: flex;
      justify-content: center;
      background-color: #98d1c8;
    }

    @media screen and (min-width: 500px) {
      .product-price {
        margin-bottom: 10px;
        color: #9a9a9a;
        font-weight: 500;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        white-space: normal;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
  
      .product-image {
        height: 230px;
        width: 230px;
        object-fit: cover;
      }

      .product-name {
        text-wrap: nowrap !important;
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 0px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: -4px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: 280px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        font-weight: 600;
      }

      .card-item {
        padding-top: 16px;
        height: 320px;
        background-color: #fff;
        .product-info {
          padding-top: 8px;
        }
        box-shadow: 2px;
  
        .product-image-group {
          padding-top: 8px;
        }
      }
    }

    @media screen and (max-width: 500px) {
      .product-price {
        margin-bottom: 10px;
        color: #9a9a9a;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
  
      .product-image {
        height: 200px;
        width: 200px;
        object-fit: cover;
      }

      .product-name {
        text-wrap: wrap !important;
        font-size: 12px;
        text-transform: capitalize;
        margin-bottom: 0px;
        -webkit-box-align: center;
        align-items: center;
        margin-top: -4px;
        display: -webkit-box;
        display: flex;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        font-weight: 600;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        white-space: normal;
        -webkit-box-orient: vertical;
      }

      .card-item {
        padding-top: 16px;
        height: 270px;
        background-color: #fff;
        .product-info {
          padding-top: 8px;
        }
        box-shadow: 2px;
  
        .product-image-group {
          padding-top: 8px;
        }
      }
    }

    .product-info {
      display: flex;
      justify-content: center;
      &.MuiGrid-item {
        padding-left: 8px;
        position: relative;;
      }
    }
  }
}

.product-list-empty {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
}