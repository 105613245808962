.slider {
  &.MuiGrid-root {
    .slick-arrow.slick-prev {
      width: 20px;
      height: 20px;
      z-index: 999;
      margin-left: 33px;
    }

    .slick-arrow.slick-next {
      width: 20px;
      height: 20px;
      z-index: 999;
      margin-right: 33px;
    }
  }

  .carousel {
    height: calc(100vw * 0.3125);
  }

  .css-1m9128y {
    position: absolute;
    bottom: 10px;
    left: auto;
    z-index: 1;
  }

  @media screen and (max-width: 500px) {
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  @media screen and (min-width: 500px) {
    img {
      object-fit: contain;
      width: 100%;
    }
  }
}
