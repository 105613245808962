.product-detail-container {
  .product-detail {
    background-image: url("../../assets/images/back-ground-body.png");
    padding-bottom: 48px;
    @media screen and (max-width: 500px) {
      width: 360px;
    }

    @media screen and (min-width: 500px) {
      width: 500px;
    }
    .css-46bh2p-MuiCardContent-root {
      padding: 0;
    }

    overflow: scroll;
    height: 100%;
  }

  .product-header {
    border-bottom: 1px solid #ebcdcb;
    padding-left: 8px;
    padding-right: 8px;
  }

  .product-detail-item {
    position: relative;
    .slide-item {
      display: flex !important;
      justify-content: center;
      text-align: center;
      background-color: #98d1c8;
     
      @media screen and (min-width: 500px) {
        img {
          height: 230px;
          width: 230px;
          object-fit: cover;
          align-self: center;
        }
      }
      @media screen and (max-width: 500px) {
        img {
          height: 200px;
          width: 200px;
          object-fit: cover;
          align-self: center;
        }
      }
    }

    .product-detail-slider {
      padding-left: 0;

      .slick-slide {
        margin-left: 0;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev {
        left: 0;
        z-index: 1;
      }
    }

    .quantity-control {
      width: 100px;
    }
  }

  .product-detail-footer {
    position: absolute;
    display: flex;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
    text-align: center;
    width: 100%;
    .MuiButtonBase-root {
      border-radius: 0;
      height: 40px;
      width: 100%;
    }
  }
}
