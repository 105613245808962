.nav-bar {
  height: 48px;
  background-color: #cec3c9;

  .slick-list {
    padding-left: 24px;
    padding-right: 24px;
  }

  .MuiTypography-root {
    text-decoration: none;
  }

  .MuiTypography-root {
    font-size: 14px;
    color: #212529 !important;
    text-transform: capitalize;
    font-weight: 600;
  }

  .slick-slide {
    height: 48px;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
  }

  .slick-arrow.slick-next {
    margin-right: 24px;
    z-index: 1;
    color: darkcyan;
  }

  .slick-arrow.slick-arrow.slick-prev {
    margin-left: 24px;
    z-index: 1;
    color: darkcyan;
  }

  .slide-item {
    .active {
      .MuiButtonBase-root {
        background-color: chocolate !important;
        .MuiTypography-root {
          color: white !important
        }
      }
    }
  }
}

.order-header {
  background-color: #a8cb9c;
  color: aliceblue;
}


.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
