.sliding {
  @media screen and (min-width: 500px) {
    & {
      height: 80px;
      background-color: #def9ff;
      border-top: 3px solid #67c2f5;
      border-bottom: 3px solid #67c2f5;
    }

    .sliding-container {
      flex-direction: row;
      gap: 16px;
      margin-left: 16px;
    }
  }

  @media screen and (max-width: 500px) {
    & {
      height: 72x;
      background-color: #def9ff;
      border-top: 3px solid #67c2f5;
      border-bottom: 3px solid #67c2f5;
    }

    .sliding-container {
      flex-direction: row;
      gap: 16px;
      margin-left: 16px;
      padding: 12px;
      img {
        height: 16px;
        object-fit: contain;
      }
    }
  }
}
